


















































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { LETTER_STATES, Project, PROJECT_STATES } from '@/model/Project'
import CardProject from '../../components/core/project/CardProject.vue'
import ProjectFormCard from '../../components/core/project/ProjectFormCard.vue'
import ProjectDetails from '../../components/core/project/ProjectDetails.vue'
import UserFormCard from '../../components/core/user/UserFormCard.vue'
import UserDetails from '../../components/core/user/UserDetails.vue'
import LetterForm from '../../components/core/project/LetterForm.vue'
import DisplayLetter from '../../components/core/project/DisplayLetter.vue'
import { User, USER_STATES } from '@/model/User'

@Component({
  components: {
    CardProject,
    ProjectFormCard,
    ProjectDetails,
    UserFormCard,
    UserDetails,
    LetterForm,
    DisplayLetter,
  },
})
export default class ViewHome extends Vue {
  @Ref('input') readonly inputEl!: HTMLInputElement

  LETTER_STATES = LETTER_STATES

  get letter(): string {
    return this.$store.getters.letter
  }

  get mainProject(): Project {
    return this.projects.find(p => p.main) as Project
  }

  get projects(): Project[] {
    return this.$store.getters.projects
  }

  get projectsToValidate(): Project[] {
    return this.$store.getters.projects.filter(
      (p: Project) =>
        p.letterState === LETTER_STATES.USER_VALIDATED ||
        p.letterState === LETTER_STATES.ADMIN_VALIDATED,
    )
  }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  get isDisabled(): boolean {
    return this.project.state === PROJECT_STATES.DISABLED
  }

  get isLetterReady(): boolean {
    return !(
      this.project.letterState === LETTER_STATES.CREATED ||
      this.project.letterState === LETTER_STATES.NOT_CREATED
    )
  }

  get isUserDisabled(): boolean {
    return this.user.state === USER_STATES.DISABLED
  }

  get user(): User {
    return this.$store.getters.loggedUser
  }

  get project(): Project {
    return this.projects[0] || {}
  }

  editing = false
  loadingLetter = false

  editLetter() {
    this.editing = true
  }

  createLetter(data: any) {
    this.loadingLetter = true
    this.$store.dispatch('createLetter', data).then(json => {
      this.loadingLetter = false
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.mainProject.letterState = LETTER_STATES.CREATED
      }
    })
  }

  validateLetterUser() {
    this.$store
      .dispatch('validateLetterUser', {
        id: this.project.id,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.mainProject.letterState = LETTER_STATES.USER_VALIDATED
        }
      })
  }

  logout() {
    this.$store.dispatch('logout')
    this.$store.dispatch('toaster/toast', {
      message: `${this.$t('goodbye')}`,
      type: 'success',
    })
    this.$router.push({ name: 'Login' })
  }

  created() {
    this.$store.dispatch('getProjects').then(() => {
      this.$store.dispatch('nav/refresh', this.$route.path)
      this.$store.dispatch('getProjectLetter', this.project.id)
    })

    if (this.$store.getters.isAdmin) {
      if (!this.$store.getters.users.length) {
        this.$store.dispatch('getUsers').then(() => {
          this.$store.dispatch('nav/refresh', this.$route.path)
        })
        this.$store.dispatch('getProjects')
      }
    }
  }
}
